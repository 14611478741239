function flyToElement(flyer, flyingTo) {
    var $func = $(this);
    var divider = 3;
    var flyerClone = $(flyer).clone();
    $(flyerClone).css({
        position: 'absolute',
        top: $(flyer).offset().top + "px",
        left: $(flyer).offset().left + "px",
        opacity: 1,
        'z-index': 1000
    });
    $('body').append($(flyerClone));
    var gotoX = $(flyingTo).offset().left + ($(flyingTo).width() / 2) - ($(flyer).width() / divider) / 2;
    var gotoY = $(flyingTo).offset().top + ($(flyingTo).height() / 2) - ($(flyer).height() / divider) / 2;

    $(flyerClone).animate({
            opacity: 0.4,
            left: gotoX,
            top: gotoY,
            width: $(flyer).width() / divider,
            height: $(flyer).height() / divider
        }, 700,
        function () {
            $(flyingTo).fadeOut('fast', function () {
                $(flyingTo).fadeIn('fast', function () {
                    $(flyerClone).fadeOut('fast', function () {
                        $(flyerClone).remove();
                    });
                });
            });
        });
}

function getWordCount(wordString) {
    var words = wordString.split(" ");
    words = words.filter(function (words) {
        return words.length > 0
    }).length;
    return words;
}

function removeElement(el) {
    el.fadeOut(function () {
        el.remove();
    });
}

function changeTotalPrice(e, price) {
    e.fadeOut(function () {
        $(this).text(price);
        $(this).fadeIn();
    });
}

csrfToken = $('meta[name="csrf-token"]').attr('content');

$(document).ready(function () {

    $('.dropdown-toggle').click(function (e) {
        e.preventDefault();
        setTimeout($.proxy(function () {
            if ('ontouchstart' in document.documentElement) {
                $(this).siblings('.dropdown-backdrop').off().remove();
            }
        }, this), 0);
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': csrfToken
        }
    });

//add the custom validation method
    jQuery.validator.addMethod("wordCount",
        function (value, element, params) {
            var count = getWordCount(value);
            if (count >= params[0]) {
                return true;
            }
        },
        jQuery.validator.format("A minimum of {0} words is required here.")
    );

    $('#main-area .validate').validate();

    $("#checkoutForm").validate({
        rules: {
            delivery_id: {
                required: true
            },
            terms: {
                required: true
            }
        }
    });

    $('.add-to-cart').on('click', function () {
        //Scroll to top if cart icon is hidden on top
        $('html, body').animate({
            'scrollTop': $(".cart_anchor").position().top
        });
        //Select item image and pass to the function
        var itemImg = $(this).parent().parent().find('img').eq(0);
        flyToElement($(itemImg), $('.cart_anchor'));
    });

    var $cardContent = $('#cardContent');
    var $totalPrice = $('.cart-total-value');
    var $totalPoints = $('.cart-total-points');
    var $totalProvisionPrice = $('.cart-total-provision');

    $cardContent.find('.close').click(function () {
        var $Item = $(this).parent().parent();
        var $productId = $Item.data('id');
        var $allProductItemElements = $('.product-item_' + $productId);
        var data = {
            id: $productId
        };

        $.post('/cart/remove', data, function (response) {

            $('.cart-menu .badge').text(response.cart.totalQty);
            removeElement($allProductItemElements);

            changeTotalPrice($totalPrice, response.cart.totalPrice);
            changeTotalPrice($totalPoints, response.cart.totalPoints);
            changeTotalPrice($totalProvisionPrice, response.cart.totalProvisionPrice);
        });
    });

    $cardContent.find('.cart-increment').click(function () {
        var $Item = $(this).parent().parent().parent();
        var $productId = $Item.data('id');
        var $allProductItemElements = $('.product-item_' + $productId);
        var currentQty = $Item.find('.qty-value').text();
        var itemPrice = $Item.find('.price-value').text();
        var $itemSubtotal = $Item.find('.subtotal-value');
        var totalCart = $('.cart-menu .badge');
        var totalCartQty = totalCart.text();

        var data = {
            id: $productId
        };
        $.post('/cart/increment', data, function (response) {
            currentQty++;
            $itemSubtotal.text(Math.round(currentQty * itemPrice * 100) / 100);
            $allProductItemElements.find('.qty-value').text(currentQty);
            totalCart.text(parseInt(totalCartQty) + 1);
            changeTotalPrice($totalPrice, response.cart.totalPrice);
            changeTotalPrice($totalPoints, response.cart.totalPoints);
            changeTotalPrice($totalProvisionPrice, response.cart.totalProvisionPrice);
        });
    });

    $cardContent.find('.cart-decrement').click(function () {
        var $Item = $(this).parent().parent().parent();
        var $productId = $Item.data('id');
        var $allProductItemElements = $('.product-item_' + $productId);
        var currentQty = $Item.find('.qty-value').text();
        var itemPrice = $Item.find('.price-value').text();
        var $itemSubtotal = $Item.find('.subtotal-value');
        var totalCart = $('.cart-menu .badge');
        var totalCartQty = totalCart.text();

        var data = {
            id: $productId
        };
        $.post('/cart/decrement', data, function (response) {
            currentQty--;

            if (currentQty == 0) {
                removeElement($Item)
            }

            $itemSubtotal.text(Math.round(currentQty * itemPrice * 100) / 100);
            $allProductItemElements.find('.qty-value').text(currentQty);
            totalCart.text(parseInt(totalCartQty) - 1);
            changeTotalPrice($totalPrice, response.cart.totalPrice);
            changeTotalPrice($totalPoints, response.cart.totalPoints);
            changeTotalPrice($totalProvisionPrice, response.cart.totalProvisionPrice);
        });
    });

    var $checkoutContent = $('#checkoutContent');

    $checkoutContent.find('input[name=delivery_id]').parent().click(function () {
        var deliveryPrice = parseFloat($('input', $(this)).data('deliveryPrice'));
        var totalPrice = parseFloat($checkoutContent.find('.total-price').data('totalPrice'));

        changeTotalPrice($checkoutContent.find('.shipping-price'), deliveryPrice);
        changeTotalPrice($checkoutContent.find('.total-price'), totalPrice + deliveryPrice);
    });

    var $calendarObject = $('#eventCalendar');
    var calendarUrl = $calendarObject.data('url');

    $calendarObject.fullCalendar({
        eventSources: [
            {
                url: calendarUrl,
                type: 'GET',
                error: function () {
                    alert('there was an error while fetching events!!');
                },
                textColor: '#ffffff',
                color: '#00c0ef',
                backgroundColor: '#00c0ef',
                editable: true,
            }
        ],
        views: {
            month: {
                timeFormat: 'H:mm'
            },
            agendaWeek: {
                slotLabelFormat: 'H:mm'
            },
            agendaDay: {
                slotLabelFormat: 'H:mm'
            }
        },
        editable: true,
        lang: 'sk',
        scrollTime: '12:00',
        //businessHours: {
        //    start: '9:00',
        //    end: '21:00'
        //},
        header: {
            left: 'title',
            center: 'month,agendaWeek',
            right: 'prev,today,next'
        },
        eventRender: function (event, element) {
            var startTime = moment(new Date(event.start)).format('D.MMM YYYY o H:mm');
            element.popover({
                html: true,
                placement: 'top',
                title: event.title,
                content: '<p>' + startTime + '<br>' + event.place + '</p>',
            });
        },
        dayClick: function () {
        },
        eventClick: function (event, jsEvent, view) {
            $(this).popover('show');
        },
        eventDrop: function () {
        },
        eventResize: function () {
        },
        eventMouseover: function (event, jsEvent, view) {
            $(this).popover('show');
        },
        eventMouseout: function (event, jsEvent, view) {
            $(this).popover('hide');
        },
    });


    var $niceUpload = $('.niceupload');
    var $uploadButton = $niceUpload.find('.uploadBtn').first();
    var $fileInput = $niceUpload.find('[type=file]').first();
    var $progressBar = $niceUpload.find('.progress > .progress-bar').first();
    var $thumb = $niceUpload.find('.thumb > img').first();
    var $wasUpload = $niceUpload.find('.thumb > [name=was_upload]').first();

    $fileInput.change(function () {
        $(this).simpleUpload("uploadFile", {

            allowedExts: ["jpg", "jpeg", "png", "gif"],
            allowedTypes: ["image/pjpeg", "image/jpeg", "image/png", "image/x-png", "image/gif", "image/x-gif"],
            maxFileSize: 3000000, //3MB in bytes

            start: function (file) {
                $progressBar.parent().show();
                $progressBar.attr('aria-valuenow', 0);
                $progressBar.css('width', '0%');
                $thumb.hide();
            },

            progress: function (progress) {
                var roundProgress = Math.round(progress);

                $progressBar.attr('aria-valuenow', progress);
                $progressBar.css('width', progress + '%');
            },

            success: function (data) {
                $progressBar.parent().hide();
                $thumb.show().attr('src', data.src);
                $wasUpload.val(true);
                $(this).val('');
            },

            error: function (error) {
                console.log("upload error: " + error.name + ": " + error.message);
            }
        });
    });

    $('.treeView .child').hide();
    $('.treeView .toggleCollapse').click(function () {
        var $this = $(this);
        var $childNode = $this.parent().next();

        if ($childNode.is(':visible')) {
            $this.find('.fa').removeClass('fa-minus').addClass('fa-plus');
        } else {
            $this.find('.fa').removeClass('fa-plus').addClass('fa-minus');
        }

        $childNode.slideToggle();
    });

    //$( 'textarea.simple_editor' ).ckeditor();

    // $('.datatable').DataTable({
    //     dom: 'Bfrtip',
    //     buttons: [
    //         // 'copy', 'csv', 'excel', 'pdf', 'print'
    //     ]
    // });

    $('.product-select-radio').click(function () {
        $(this).closest('.content').find('.category-product').removeClass('category-product--selected').css({'opacity': .5});
        $(this).closest('.category-product').addClass('category-product--selected').css({'opacity': 1});
    });
});