$(document).ready(function(){

    function cb(start, end) {
        var dateRangeElement = $('.daterange');

        if(dateRangeElement.length === 0) {
            return false;
        }

        var $userId = dateRangeElement.data('userid');

        loadStats(start,end, $userId);
        $('.daterange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    function loadDistributors(from, to) {

        var $selector = $('.daterange-distributors');
        var $tableBody = $('.distributors-table tbody');

        if($selector.length === 0) {
            return false;
        }

        $tableBody.html('<tr>' + '<td colspan="3">Loading ...</td>' + '</tr>');

        $selector.find('span').html(from.format('MMMM D, YYYY') + ' - ' + to.format('MMMM D, YYYY'));

        $.get($selector.data('loadurl'), {start: from.format('YYYY-MM-DD'), end: to.format('YYYY-MM-DD'), user_id: $selector.data('userid')}, function (response) {

            $tableBody.html('');

            for(var i = 0; i < response.length;i++) {
                $('.distributors-table tbody').append('<tr> <td><a href="' + response[i].detailUrl + '">'+response[i].full_name+'</a></td> <td> '+response[i].allTurnoverPoints+'</td> <td> '+response[i].allTurnoverPos+' </td> </tr>')
            }
        });
    }

    function loadStats(from, to, userId) {
        var urls = $('#statsContainer').data('urls');

        if(typeof urls != 'undefined') {
            $.get(urls.allTurnover, {start: from.format('YYYY-MM-DD'), end: to.format('YYYY-MM-DD'), userId: userId}, function (response) {
                $('.allTurnover-points').text(response.points);
                $('.allTurnover-price').text(response.basic_price_for_provision);
                $('.allTurnover-pos').text(response.pos_turnover_price);
            });

            $.get(urls.selfTurnover, {start: from.format('YYYY-MM-DD'), end: to.format('YYYY-MM-DD'), userId: userId}, function (response) {
                $('.selfTurnover-points').text(response.points);
            });

            $.get(urls.usersFlow, {start: from.format('YYYY-MM-DD'), end: to.format('YYYY-MM-DD'), userId: userId}, function (response) {
                $('.users-active').text(response.active);
                $('.users-distributor').text(response.distributor);
            });
        }
    }

    var start = moment().startOf('month');
    var end = moment().endOf('month');

    $('.daterange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, cb);

    cb(start, end);


    $('.daterange-distributors').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, loadDistributors);

    loadDistributors(start,end)
});